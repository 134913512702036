@charset 'UTF-8';

.homepage,
.homepage-framed-content {
  color: var(--color-black);
  text-shadow: var(--text-shadow-oyster-shell);
}

.homepage {
  --size: 100%;
  --font-size: var(--font-size-h4-fluid);

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  /* font-family: var(--font-family-libre-baskerville-bold); */
  font-size: var(--font-size);
  height: var(--size);
  letter-spacing: 0.05em;
  line-height: calc(var(--font-size) * 1.5);
  place-content: center center;
  text-align: center;
}

.homepage-framed-content {
  --vertical-offset: calc(var(--gutter) * 3.75);

  display: flex;
  margin-top: calc(var(--vertical-offset) * -1);
  text-decoration: none;
}

@media (width <= 540px) {
  .homepage {
    --font-size: var(--font-size-h5-fluid);
  }

  .homepage-framed-content {
    --vertical-offset: calc(var(--gutter) * 7);
  }
}

@media (width <= 375px) {
  .homepage {
    --font-size: var(--font-size-h6-fluid);
  }

  .homepage-framed-content {
    --vertical-offset: calc(var(--gutter) * 5);
  }
}

/* ----------------------------------------------- */
.site-menu {
  --size: 100%;
  --font-size: var(--font-size-h5-fluid);

  align-items: center;
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  /* font-family: var(--font-family-libre-baskerville-bold); */
  font-size: var(--font-size);
  height: var(--size);

  /* letter-spacing: 0.125em; */
  line-height: calc(var(--font-size) * 1.5);
  place-content: center center;
  text-align: center;
}

/* ----------------------------------------------- */
.narrative-content,
.gallery-content,
.contact-page-content {
  --horizontal-freespace: calc(var(--gutter) * 2);
  --vertical-freespace: calc(var(--gutter) * 3);

  color: var(--color-white);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--horizontal-freespace);
  padding-right: var(--horizontal-freespace);
  padding-top: var(--vertical-freespace);
}

.gallery-content {
  --vertical-freespace: calc(var(--gutter) * 1.5);
}

.narrative-content .content-container {
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}

.narrative-content .content-container p,
.narrative-content .content-container span {
  line-height: calc(var(--type-scale-golden-ratio) * 1.5);
}

/* stylelint-disable-next-line selector-max-compound-selectors */
[data-route="about"] .narrative-content .content-container p {
  font-size: var(--font-size-body);
  margin-bottom: 0;
  margin-top: 0;
}

[data-route="about"] .narrative-content .content-container {
  max-width: 46ch;
}

[data-route="about"] .rich-text-content-from-cms {
  margin-top: calc(var(--gutter) / 2);
}

/* ----------------------------------------------- */
.gallery-content-viewer,
.gallery-content-viewer-cell,
.gallery-navigation-button {
  --gallery-navigation-button-button-size: 64px;
}

.gallery-content-viewer {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: calc(var(--gutter) / 2) calc(var(--gutter) * 2);
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  place-content: stretch center;
}

.gallery-content-viewer-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.gallery-content-viewer-navigation {
  align-self: center;
  flex-basis: var(--gallery-navigation-button-button-size);
  width: var(--gallery-navigation-button-button-size);
}

.gallery-content-viewer-navigation .gallery-navigation-button {
  margin-top: calc(var(--gallery-navigation-button-button-size) / 2 * -1);
}

.gallery-content-viewer-media {
  --preferred-height: 414px;

  flex-grow: 1;
  flex-shrink: 1;
  height: var(--preferred-height);
  overflow: hidden;
  position: relative;
  text-align: center;
}

.gallery-content-viewer-cell.gallery-content-viewer-wall-label {
  flex-basis: 100%;
}

/* ----------------------------------------------- */
.gallery-media img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  /* background-color: var(--color-black-800); */
  display: block;
  height: var(--media-size);
  left: var(--media-position);
  object-fit: contain;

  /* object-position: center bottom; */
  object-position: center center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
  z-index: 0;
}

/* ----------------------------------------------- */
@keyframes loader-rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.gallery-media-loading-indicator {
  --indicator-size: 84px;
  --indicator-position: calc(50% - var(--indicator-size) / 2);
  --indicator-vertical-offset: calc(var(--indicator-size) / 6 * -1);
  --surface-color: transparent;
  --on-surface-color: var(--color-black-500);

  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: loader-rotation;
  animation-play-state: running;
  animation-timing-function: linear;
  background-color: var(--surface-color);
  border: 4px solid var(--on-surface-color);
  border-bottom-color: transparent;
  border-radius: 100%;
  height: var(--indicator-size);
  left: var(--indicator-position);
  margin-top: var(--indicator-vertical-offset);
  pointer-events: none;
  position: absolute;
  top: var(--indicator-position);
  width: var(--indicator-size);
  z-index: 1;
}

[data-is-loaded="true"] .gallery-media-loading-indicator {
  animation-play-state: paused;
  visibility: hidden;
  z-index: -1;
}

/* ----------------------------------------------- */
@media (width <= 1024px) {
  .gallery-content {
    --horizontal-freespace: var(--gutter);
  }

  .gallery-content-viewer {
    column-gap: var(--gutter);
  }

  .gallery-content-viewer,
  .gallery-content-viewer-cell,
  .gallery-navigation-button {
    --gallery-navigation-button-button-size: 54px;
  }
}

/* ----------------------------------------------- */

@media (width <= 768px) {
  .gallery-content-viewer-media {
    --preferred-height: 314px;
  }
}

/* ----------------------------------------------- */
@media (width <= 540px) {
  .gallery-content-viewer,
  .gallery-content-viewer-cell,
  .gallery-navigation-button {
    --gallery-navigation-button-button-size: 36px;
  }

  main.gallery-content {
    --horizontal-freespace: calc(var(--gutter) / 2);
  }

  .gallery-content-viewer-media {
    --preferred-height: 214px;
  }
}

/* ----------------------------------------------- */
@media (width <= 480px) {
  [data-route="about"] .narrative-content .content-container {
    text-align: left;
  }
}
