@charset 'UTF-8';

.navigation-drawer {
  --surface-color: var(--color-black-900);
  --on-surface-color: var(--color-white);
  --position-xy: 0;
  --size: 100%;
  --font-size: var(--font-size-h5-fluid);

  align-items: center;
  background-color: var(--surface-color);
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: var(--font-size);
  height: var(--size);
  left: var(--position-xy);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  place-content: center;
  pointer-events: none;
  position: fixed;
  text-align: center;
  top: var(--position-xy);
  visibility: hidden;
  width: 100vw;
  z-index: -1;
}

/* ----------------------------------------------- */
.navigation-drawer[data-navigation-drawer-is-open="true"] {
  pointer-events: auto;
  visibility: visible;
}
