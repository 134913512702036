/* stylelint-disable declaration-no-important */

@charset 'UTF-8';

/*
  Custom CSS Reset
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

[data-document-ready="false"] * {
  transition: none !important;
}

html,
body {
  height: 100%;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root {
  isolation: isolate;
}

/*
  End Custom CSS Reset
*/

/* ----------------------------------------------- */
body {
  background-color: white;
  color: var(--color-black);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

img,
embed,
svg,
object,
video {
  border: 0;
  content-visibility: auto;
  display: inline-block;
  height: auto;
  image-rendering: high-quality;
  max-width: 100%;
  overflow: hidden;
}

img {
  width: 100%;
}

img,
svg {
  display: block;
  position: relative;
}

.img-eager {
  content-visibility: visible;
}

/* ----------------------------------------------- */
circle,
ellipse,
line,
path,
polygon,
polyline,
rect {
  /* shape-rendering: optimizespeed; */
  shape-rendering: geometricprecision;
}

rect {
  shape-rendering: crispedges;
}

/* ----------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl,
legend {
  display: inherit;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  line-height: var(--type-scale-perfect-fourth);
  margin-bottom: 0;
  margin-top: 0;
  text-rendering: geometricprecision;
}

address {
  font-style: normal;
}

a {
  text-decoration: none;
}

a > img,
a > svg,
a:has(img),
a:has(svg) {
  display: inline-flex;
  outline: none !important;
}

/* ----------------------------------------------- */
button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  background-image: none;
  border: none;
  cursor: pointer;
  display: block;
  outline-color: transparent;
  padding: 0;
}

/* ----------------------------------------------- */
.hide {
  border-width: 0 !important;
  font-size: 0 !important;
  left: -9999em;
  margin: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute;
  text-indent: 100% !important;
  white-space: nowrap !important;
  z-index: -100;
}

/* ----------------------------------------------- */
:root {
  --color-neutral-900: #201e22;
  --color-neutral-800: #413e43;
  --color-neutral-700: #605d62;
  --color-neutral-600: #747176;
  --color-neutral-500: #9d999f;
  --color-neutral-400: #bcb9be;
  --color-neutral-300: #e0dce2;
  --color-neutral-200: #eeebf1;
  --color-neutral-100: #f6f3f9;
  --color-neutral-050: #fcf8fe;
  --easing-point-to-point: ease-in-out;
  --easing-entering: ease-out;
  --easing-exiting: ease-in;
  --easing-color-or-opacity: linear;
  --easing-snap: cubic-bezier(0, 0.8, 0.2, 1);
  --letter-spacing-tight: -0.055em;
  --letter-spacing-all-caps: 0.055em;
  --rounded-corners-extra-extra-large: 24px;
  --rounded-corners-extra-large: 20px;
  --rounded-corners-large: 10px;
  --rounded-corners-medium: 8px;
  --rounded-corners-small: 4px;
  --shadow-color: 0deg 0% 0%;
  --shadow-strength: 40%;
  --shadow-elevation-low: 0 1px 1.1px hsl(var(--shadow-color) / var(--shadow-strength)),
    0 4px 4.5px -2.5px hsl(var(--shadow-color) / var(--shadow-strength));
  --shadow-elevation-medium: 0 1px 1.1px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 2)),
    0.1px 5.2px 5.9px -1.2px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 2)),
    0.2px 20px 22.5px -2.5px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 2));
  --shadow-elevation-high: 0 1px 1.1px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 3)),
    0.1px 9.5px 10.7px -0.6px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 3)),
    0.3px 22.7px 25.5px -1.2px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 3)),
    0.6px 49.7px 55.9px -1.9px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 3)),
    1.2px 100px 112.5px -2.5px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 3));
  --speed-extra-slow: 500ms;
  --speed-slow: 400ms;
  --speed-normal: 300ms;
  --speed-fast: 200ms;
  --speed-extra-fast: 100ms;
  --speed-super-fast: 10ms;
  --type-scale-octave: 2;
  --type-scale-golden-ratio: 1.618;
  --type-scale-perfect-fourth: 1.333;
  --type-scale-major-second: 1.125;
}

/* stylelint-disable declaration-no-important */
@charset 'UTF-8';

:root {
  --animation-delay-short: 0.1s;
  --animation-delay-medium: 0.5s;
  --color-black: black;
  --color-white: white;
  --color-error: #d51f31;
  --color-success: #166534;

  /* Base: 900 */
  --color-black-900: #231f20;
  --color-black-800: #444041;
  --color-black-700: #56575a;
  --color-black-600: #777374;
  --color-black-500: #a19b9d;
  --color-black-400: #c0babc;
  --color-black-300: #e3dedf;
  --color-black-200: #f2eced;
  --color-black-100: #f9f3f5;
  --color-black-050: #fef8fa;
  --color-oyster-shell-900: #1d1b19;
  --color-oyster-shell-800: #3d3b39;
  --color-oyster-shell-700: #5c5a57;
  --color-oyster-shell-600: #706d6b;
  --color-oyster-shell-500: #989593;
  --color-oyster-shell-400: #b8b5b2;

  /* Base: 300 */
  --color-oyster-shell-300: #dcd9d6;
  --color-oyster-shell-200: #ece9e6;
  --color-oyster-shell-100: #f5f2ef;
  --color-oyster-shell-050: #fcf8f5;
  --font-family-libre-baskerville-bold: "libre-baskerville/bold", "Helvetica", "Arial", sans-serif;
  --font-family-libre-baskerville-italic: "libre-baskerville/italic", "Helvetica", "Arial",
    sans-serif;
  --font-family-libre-baskerville-regular: "libre-baskerville/regular", "Helvetica", "Arial",
    sans-serif;
  --font-size-h1: 96px;
  --font-size-h2: 60px;
  --font-size-h3: 48px;
  --font-size-h4: 34px;
  --font-size-h5: 24px;
  --font-size-h6: 20px;
  --font-size-body: 16px;
  --font-size-body-big: 23px;
  --font-size-body-small: 14px;
  --font-size-body-extra-small: 12px;
  --font-size-h1-fluid: clamp(3.355rem, 2.64rem + 3.586vw, 6.941rem);
  --font-size-h2-fluid: clamp(2.684rem, 2.178rem + 2.53vw, 5.214rem);
  --font-size-h3-fluid: clamp(2.145rem, 1.793rem + 1.76vw, 3.905rem);
  --font-size-h4-fluid: clamp(1.716rem, 1.474rem + 1.21vw, 2.936rem);
  --font-size-h5-fluid: clamp(1.375rem, 1.21rem + 0.825vw, 2.2rem);
  --font-size-h6-fluid: clamp(1.1rem, 0.99rem + 0.55vw, 1.65rem);
  --font-size-body-fluid: clamp(0.88rem, 0.814rem + 0.363vw, 1.243rem);
  --font-size-body-big-fluid: clamp(0.968rem, 0.8954rem + 0.3993vw, 1.3673rem);
  --font-size-body-small-fluid: clamp(0.704rem, 0.66rem + 0.22vw, 0.924rem);
  --font-size-button: 18px;
  --gutter: 2rem;
  --text-shadow-white: hsl(0deg 0% 100% / 75%) 0 1px 1px;
  --text-shadow-oyster-shell: hsl(30deg 8% 85% / 75%) 0 1px 1px;
  --text-shadow-black: hsl(0deg 0% 0% / 75%) 0 1px 1px;
}

@font-face {
  font-display: fallback;
  font-family: "libre-baskerville/bold";
  src: url("/fonts/libre-baskerville/bold.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "libre-baskerville/italic";
  src: url("/fonts/libre-baskerville/italic.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "libre-baskerville/regular";
  src: url("/fonts/libre-baskerville/regular.woff2") format("woff2");
}

[data-document-ready="false"] *,
[data-document-ready="false"] *::after,
[data-document-ready="false"] *::before {
  animation-play-state: paused !important;
}

@media (prefers-reduced-motion) {
  [data-document-ready="true"] *,
  [data-document-ready="true"] *::after,
  [data-document-ready="true"] *::before {
    animation-play-state: paused !important;
  }
}

/* ----------------------------------------------- */
body {
  background-color: var(--color-black-900);
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--color-white);
  font-family: var(--font-family-libre-baskerville-regular);
}

/* ----------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl,
legend {
  --vertical-margin: calc(var(--gutter) / 2);

  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
}

/* ----------------------------------------------- */
strong {
  font-family: var(--font-family-libre-baskerville-bold);
  font-style: normal;
}

/* ----------------------------------------------- */
h1,
h2,
h3 {
  font-family: var(--font-family-libre-baskerville-bold);
  font-size: var(--font-size-h4-fluid);
}

h1 {
  margin-top: 0;
}

h2 {
  font-size: var(--font-size-h6-fluid);
}

p {
  font-size: var(--font-size-body-fluid);
  line-height: var(--type-scale-golden-ratio);
}

/* ----------------------------------------------- */

.small-text {
  font-size: var(--font-size-body-small);
}

hr {
  --ruler-height: 2px;
  --vertical-margin: var(--gutter);

  background-color: var(--color-white);
  border: none;
  height: var(--ruler-height);
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
  padding: 0;
  width: 100%;
}

/* ----------------------------------------------- */
.button-unstyled,
.button-unstyled:hover,
.button-unstyled:active,
.button-unstyled:focus {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  transition-property: none;
}

/* ----------------------------------------------- */
.contact-points a {
  color: var(--color-black);
}

/* ----------------------------------------------- */
body[data-route="home"] {
  background-image: url("/img/homepage-background-with-frame.png");
}

/* ----------------------------------------------- */
@media (width <= 540px) {
  body[data-route="home"] {
    background-image: url("/img/homepage-background-with-frame-small.jpg");
  }
}

/* ----------------------------------------------- */
.suspense-loader {
  --position-xy: 0;

  align-items: center;
  background-color: var(--color-black-900);
  color: var(--color-white);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: var(--position-xy);
  pointer-events: none;

  /* position: fixed; */
  top: var(--position-xy);
  visibility: hidden;
  width: 100vw;
  z-index: 1000;
}

@charset 'UTF-8';

.homepage,
.homepage-framed-content {
  color: var(--color-black);
  text-shadow: var(--text-shadow-oyster-shell);
}

.homepage {
  --size: 100%;
  --font-size: var(--font-size-h4-fluid);

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  /* font-family: var(--font-family-libre-baskerville-bold); */
  font-size: var(--font-size);
  height: var(--size);
  letter-spacing: 0.05em;
  line-height: calc(var(--font-size) * 1.5);
  place-content: center center;
  text-align: center;
}

.homepage-framed-content {
  --vertical-offset: calc(var(--gutter) * 3.75);

  display: flex;
  margin-top: calc(var(--vertical-offset) * -1);
  text-decoration: none;
}

@media (width <= 540px) {
  .homepage {
    --font-size: var(--font-size-h5-fluid);
  }

  .homepage-framed-content {
    --vertical-offset: calc(var(--gutter) * 7);
  }
}

@media (width <= 375px) {
  .homepage {
    --font-size: var(--font-size-h6-fluid);
  }

  .homepage-framed-content {
    --vertical-offset: calc(var(--gutter) * 5);
  }
}

/* ----------------------------------------------- */
.site-menu {
  --size: 100%;
  --font-size: var(--font-size-h5-fluid);

  align-items: center;
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  /* font-family: var(--font-family-libre-baskerville-bold); */
  font-size: var(--font-size);
  height: var(--size);

  /* letter-spacing: 0.125em; */
  line-height: calc(var(--font-size) * 1.5);
  place-content: center center;
  text-align: center;
}

/* ----------------------------------------------- */
.narrative-content,
.gallery-content,
.contact-page-content {
  --horizontal-freespace: calc(var(--gutter) * 2);
  --vertical-freespace: calc(var(--gutter) * 3);

  color: var(--color-white);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--horizontal-freespace);
  padding-right: var(--horizontal-freespace);
  padding-top: var(--vertical-freespace);
}

.gallery-content {
  --vertical-freespace: calc(var(--gutter) * 1.5);
}

.narrative-content .content-container {
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}

.narrative-content .content-container p,
.narrative-content .content-container span {
  line-height: calc(var(--type-scale-golden-ratio) * 1.5);
}

/* stylelint-disable-next-line selector-max-compound-selectors */
[data-route="about"] .narrative-content .content-container p {
  font-size: var(--font-size-body);
  margin-bottom: 0;
  margin-top: 0;
}

[data-route="about"] .narrative-content .content-container {
  max-width: 46ch;
}

[data-route="about"] .rich-text-content-from-cms {
  margin-top: calc(var(--gutter) / 2);
}

/* ----------------------------------------------- */
.gallery-content-viewer,
.gallery-content-viewer-cell,
.gallery-navigation-button {
  --gallery-navigation-button-button-size: 64px;
}

.gallery-content-viewer {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: calc(var(--gutter) / 2) calc(var(--gutter) * 2);
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  place-content: stretch center;
}

.gallery-content-viewer-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.gallery-content-viewer-navigation {
  align-self: center;
  flex-basis: var(--gallery-navigation-button-button-size);
  width: var(--gallery-navigation-button-button-size);
}

.gallery-content-viewer-navigation .gallery-navigation-button {
  margin-top: calc(var(--gallery-navigation-button-button-size) / 2 * -1);
}

.gallery-content-viewer-media {
  --preferred-height: 414px;

  flex-grow: 1;
  flex-shrink: 1;
  height: var(--preferred-height);
  overflow: hidden;
  position: relative;
  text-align: center;
}

.gallery-content-viewer-cell.gallery-content-viewer-wall-label {
  flex-basis: 100%;
}

/* ----------------------------------------------- */
.gallery-media img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  /* background-color: var(--color-black-800); */
  display: block;
  height: var(--media-size);
  left: var(--media-position);
  -o-object-fit: contain;
     object-fit: contain;

  /* object-position: center bottom; */
  -o-object-position: center center;
     object-position: center center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
  z-index: 0;
}

/* ----------------------------------------------- */
@keyframes loader-rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.gallery-media-loading-indicator {
  --indicator-size: 84px;
  --indicator-position: calc(50% - var(--indicator-size) / 2);
  --indicator-vertical-offset: calc(var(--indicator-size) / 6 * -1);
  --surface-color: transparent;
  --on-surface-color: var(--color-black-500);

  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: loader-rotation;
  animation-play-state: running;
  animation-timing-function: linear;
  background-color: var(--surface-color);
  border: 4px solid var(--on-surface-color);
  border-bottom-color: transparent;
  border-radius: 100%;
  height: var(--indicator-size);
  left: var(--indicator-position);
  margin-top: var(--indicator-vertical-offset);
  pointer-events: none;
  position: absolute;
  top: var(--indicator-position);
  width: var(--indicator-size);
  z-index: 1;
}

[data-is-loaded="true"] .gallery-media-loading-indicator {
  animation-play-state: paused;
  visibility: hidden;
  z-index: -1;
}

/* ----------------------------------------------- */
@media (width <= 1024px) {
  .gallery-content {
    --horizontal-freespace: var(--gutter);
  }

  .gallery-content-viewer {
    -moz-column-gap: var(--gutter);
         column-gap: var(--gutter);
  }

  .gallery-content-viewer,
  .gallery-content-viewer-cell,
  .gallery-navigation-button {
    --gallery-navigation-button-button-size: 54px;
  }
}

/* ----------------------------------------------- */

@media (width <= 768px) {
  .gallery-content-viewer-media {
    --preferred-height: 314px;
  }
}

/* ----------------------------------------------- */
@media (width <= 540px) {
  .gallery-content-viewer,
  .gallery-content-viewer-cell,
  .gallery-navigation-button {
    --gallery-navigation-button-button-size: 36px;
  }

  main.gallery-content {
    --horizontal-freespace: calc(var(--gutter) / 2);
  }

  .gallery-content-viewer-media {
    --preferred-height: 214px;
  }
}

/* ----------------------------------------------- */
@media (width <= 480px) {
  [data-route="about"] .narrative-content .content-container {
    text-align: left;
  }
}

@charset 'UTF-8';

.hero-picture {
  aspect-ratio: 2 / 1;
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
  position: relative;
}

.hero-picture img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  background-color: var(--color-black-800);
  display: block;
  height: var(--media-size);
  left: var(--media-position);
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
  z-index: 0;
}

@charset 'UTF-8';

.site-menu-button,
.navigation-drawer {
  --site-menu-button-size: 64px;
}

.site-menu-button {
  --surface-color: transparent;
  --outline-color: transparent;

  align-items: center;
  background-color: var(--surface-color);
  border-color: var(--outline-color);
  border-radius: 100%;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: var(--site-menu-button-size);
  place-content: center center;
  position: relative;
  row-gap: calc(var(--gutter) / 4);
  width: var(--site-menu-button-size);
}

.site-menu-button em,
.site-menu-button em::before {
  --bar-color: var(--color-white);
  --bar-height: 2px;
  --bar-width: 60%;

  background-color: var(--bar-color);
  height: var(--bar-height);
  width: var(--bar-width);
}

.site-menu-button em {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  height: var(--bar-height);
  transform-origin: center;
  width: var(--bar-width);
}

.site-menu-button em:nth-child(2)::before {
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
}

[data-route="home"] .site-menu-button em,
[data-route="home"].site-menu-button em::before {
  --bar-color: var(--color-black-900);
}

/* ----------------------------------------------- */
.site-menu-button:hover {
  --surface-color: var(--color-black-800);
  --outline-color: var(--color-black-700);
}

.site-menu-button[data-menu-is-open="true"] {
  --surface-color: var(--color-black-800);
}

.site-menu-button[data-menu-is-open="true"] em,
.site-menu-button[data-menu-is-open="true"] em::before {
  background-color: var(--color-white);
}

[data-route="home"] .site-menu-button:hover {
  --surface-color: var(--color-black-400);
}

/* ----------------------------------------------- */
.site-menu-button[data-menu-is-open="true"] em:nth-child(1),
.site-menu-button[data-menu-is-open="true"] em:nth-child(3) {
  background-color: transparent;
}

.site-menu-button[data-menu-is-open="true"] em:nth-child(2) {
  rotate: 45deg;
}

.site-menu-button[data-menu-is-open="true"] em:nth-child(2)::before {
  opacity: 1;
  rotate: -90deg;
}

/* ----------------------------------------------- */
.gallery-navigation-button {
  --surface-color: transparent;
  --on-surface-color: var(--color-white);
  --outline-color: transparent;

  align-items: center;
  background-color: var(--surface-color);
  border-color: var(--outline-color);
  border-radius: 100%;
  border-style: solid;
  border-width: 1px;
  color: var(--on-surface-color);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: var(--gallery-navigation-button-button-size);
  place-content: center center;
  position: relative;
  row-gap: calc(var(--gutter) / 4);
  transition-duration: var(--speed-normal);
  transition-property: background-color;
  transition-timing-function: var(--easing-color-or-opacity);
  width: var(--gallery-navigation-button-button-size);
}

.gallery-navigation-button svg {
  fill: currentcolor;
  position: absolute;
  width: 75%;
}

.gallery-navigation-button[data-gallery-navigation-direction="back"] svg {
  transform: rotate(180deg);
}

.gallery-navigation-button:hover {
  --surface-color: var(--color-black-800);

  transition-duration: var(--speed-extra-fast);
}

.gallery-navigation-button:active,
.gallery-navigation-button:focus {
  --surface-color: var(--color-black-700);

  transition-property: none;
}

/* ----------------------------------------------- */
@media (width <= 768px) {
  .site-menu-button,
  .navigation-drawer {
    --site-menu-button-size: 48px;
  }
}

@charset 'UTF-8';

.stacked-navigation {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: -4vh;
  padding: 0;
  place-content: center center;
  row-gap: calc(var(--gutter) * 1.5);
}

.stacked-navigation li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
}

.stacked-navigation a {
  align-items: flex-end;
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  outline: none;
  place-content: center center;
  position: relative;
}

.stacked-navigation a::after {
  --decoration-color: var(--color-white);
  --decoration-height: 2px;
  --decoration-width: 0;

  background-color: var(--decoration-color);
  bottom: calc(var(--decoration-height) * 2 * -1);
  content: "";
  display: block;
  height: var(--decoration-height);
  opacity: 0;
  position: absolute;
  transition-duration: var(--speed-fast), var(--speed-fast);
  transition-property: opacity, width;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-exiting);
  width: var(--decoration-width);
}

.stacked-navigation a:hover::after,
.stacked-navigation a:active::after,
.stacked-navigation a:focus::after,
.stacked-navigation a[aria-current="page"]::after,
.stacked-navigation a[data-is-in-current-directory="true"]::after {
  --decoration-width: 100%;

  opacity: 1;
  transition-duration: var(--speed-extra-fast), var(--speed-extra-fast);
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-exiting);
}

.stacked-navigation a:active::after,
.stacked-navigation a:focus::after,
.stacked-navigation a[aria-current="page"]::after,
.stacked-navigation a[data-is-in-current-directory="true"]::after {
  --decoration-color: var(--color-black-500);

  transition-property: none;
}

/* ----------------------------------------------- */
.wall-label {
  --width: 320px;
  --position-x: calc(50% - var(--width) / 2);
  --position-x-offset: calc(100% / 3);

  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: var(--font-size-body-fluid);
  left: var(--position-x);
  margin-left: auto;
  margin-right: auto;
  place-content: center flex-start;
  position: absolute;
  row-gap: calc(var(--gutter) * 0.75);
  transform: translateX(var(--position-x-offset));
  width: var(--width);
}

.wall-label dt {
  display: none;
}

/* ----------------------------------------------- */
@media (width <= 480px) {
  .wall-label {
    --width: 275px;
  }
}

@charset 'UTF-8';

.navigation-drawer {
  --surface-color: var(--color-black-900);
  --on-surface-color: var(--color-white);
  --position-xy: 0;
  --size: 100%;
  --font-size: var(--font-size-h5-fluid);

  align-items: center;
  background-color: var(--surface-color);
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: var(--font-size);
  height: var(--size);
  left: var(--position-xy);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  place-content: center;
  pointer-events: none;
  position: fixed;
  text-align: center;
  top: var(--position-xy);
  visibility: hidden;
  width: 100vw;
  z-index: -1;
}

/* ----------------------------------------------- */
.navigation-drawer[data-navigation-drawer-is-open="true"] {
  pointer-events: auto;
  visibility: visible;
}

@charset 'UTF-8';

.top-app-bar {
  --horizontal-freespace: var(--gutter);
  --vertical-freespace: calc(var(--gutter) / 2);

  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: var(--horizontal-freespace);
  padding-right: var(--horizontal-freespace);
  padding-top: var(--vertical-freespace);
  place-content: center flex-start;
  position: absolute;
  width: 100%;
  z-index: 100;
}

/* ----------------------------------------------- */

/* [data-route="home"] .top-app-bar {
  display: none;
} */

[data-route="menu"] .site-menu-button {
  display: none;
}

/* ----------------------------------------------- */
@media (width <= 540px) {
  .top-app-bar {
    --horizontal-freespace: calc(var(--gutter) / 2);
  }

  .site-menu-button {
    margin-left: 6px;
  }
}

@charset 'UTF-8';

.contact-form,
.contact-form-cell {
  --gap: calc(var(--gutter) * 2);
  --cell-width: calc(50% - var(--gap) / 2);
}

.contact-form {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: calc(var(--gap) / 2) var(--gap);
  place-content: flex-start space-evenly;
}

.contact-form-cell {
  align-self: auto;
  flex-basis: var(--cell-width);
  flex-grow: 1;
  flex-shrink: 1;
}

.contact-form-cell-email,
.contact-form-message,
.contact-form-submit {
  --cell-width: 100%;
}

/* ----------------------------------------------- */
@media (width <= 540px) {
  .contact-form,
  .contact-form-cell {
    --cell-width: 100%;
  }
}

/* ----------------------------------------------- */
label,
input,
select,
button[type="submit"],
textarea {
  --font-size: var(--font-size-body-fluid);

  -webkit-appearance: none;

     -moz-appearance: none;

          appearance: none;
  font-size: var(--font-size);
  line-height: var(--type-scale-perfect-fourth);
}

textarea {
  --default-height: calc(var(--font-size) * 8);

  height: var(--default-height);
  resize: vertical;
}

/* ----------------------------------------------- */
label,
label em {
  color: var(--color-white);
  display: block;
  font-family: var(--font-family-libre-baskerville-bold);
}

label {
  position: relative;
}

label em {
  font-style: normal;
  margin-bottom: calc(var(--font-size) * 0.5);
}

/* ----------------------------------------------- */
input,
select,
textarea {
  --border-width: 1px;
  --surface-color: var(--color-black-900);
  --on-surface-color: var(--color-white);
  --padding: calc(var(--font-size) * 0.75);

  background-color: var(--surface-color);
  border-color: var(--on-surface-color);
  border-radius: 0;
  border-style: solid;
  border-width: var(--border-width);
  color: var(--on-surface-color);
  display: inline-flex;
  font-family: var(--font-family-libre-baskerville-regular);
  outline-color: transparent;
  outline-style: solid;
  outline-width: calc(var(--border-width) * 4);
  padding-bottom: var(--padding);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  transition-duration: var(--speed-fast);
  transition-property: outline-color;
  transition-timing-function: var(--easing-color-or-opacity);
  width: 100%;
}

input:hover,
select:hover,
textarea:hover {
  border-color: var(--color-black-600);
}

input:focus,
select:focus,
textarea:focus {
  border-color: var(--color-black-500);
  outline-color: var(--color-black-100);
  transition-duration: var(--speed-extra-fast);
}

button.contact-form-submit-button {
  --font-size: var(--font-size-body);
  --padding: calc(var(--font-size) * 0.75);
  --surface-color: var(--color-black-700);
  --on-surface-color: var(--color-white);

  align-items: center;
  background-color: var(--surface-color);
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  color: var(--on-surface-color);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-libre-baskerville-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);
  padding-bottom: var(--padding);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  place-content: center center;
  text-align: center;
  transition-duration: var(--speed-extra-fast), var(--speed-extra-fast);
  transition-property: background-color, color;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity);
  width: 120px;
}

button.contact-form-submit-button:hover {
  --surface-color: var(--color-black-600);

  border-color: var(--color-white);
}

button.contact-form-submit-button:active,
button.contact-form-submit-button:focus {
  --surface-color: var(--color-black-800);

  border-color: var(--color-black-500);
  transition-property: none;
}

/* ----------------------------------------------- */
form [role="alert"] {
  --font-size: var(--font-size-body-small);

  border-left: 4px solid var(--color-error);
  color: var(--color-white);
  display: block;
  font-family: var(--font-family-libre-baskerville-bold);
  font-size: var(--font-size);
  line-height: var(--type-scale-perfect-fourth);
  margin-bottom: 0;
  margin-top: calc(var(--gutter) / 4);
  padding-left: calc(var(--gutter) / 4);
}

/* ----------------------------------------------- */
.form-submit-with-success-message {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: calc(var(--gutter) / 2);
  place-content: center flex-start;
}

.form-submit-with-success-message-cell,
.form-submit-with-error-message-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
}

/* ----------------------------------------------- */
@media (width <= 480px) {
  .form-submit-with-success-message,
  .form-submit-with-error-message {
    flex-wrap: wrap;
  }

  .form-submit-with-success-message-cell,
  .form-submit-with-error-message-cell {
    flex-basis: 100%;
    flex-grow: 1;
  }
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/527369e98af789af-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/2cc299267e238aca-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/16993c71e2f64545-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'customFont Fallback';src: local("Arial");ascent-override: 83.43%;descent-override: 23.22%;line-gap-override: 0.00%;size-adjust: 116.27%
}.__className_f76cb0 {font-family: 'customFont', 'customFont Fallback', Helvetica, ui-sans-serif
}

