@charset 'UTF-8';

.hero-picture {
  aspect-ratio: 2 / 1;
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
  position: relative;
}

.hero-picture img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  background-color: var(--color-black-800);
  display: block;
  height: var(--media-size);
  left: var(--media-position);
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
  z-index: 0;
}
