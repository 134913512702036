@charset 'UTF-8';

.contact-form,
.contact-form-cell {
  --gap: calc(var(--gutter) * 2);
  --cell-width: calc(50% - var(--gap) / 2);
}

.contact-form {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: calc(var(--gap) / 2) var(--gap);
  place-content: flex-start space-evenly;
}

.contact-form-cell {
  align-self: auto;
  flex-basis: var(--cell-width);
  flex-grow: 1;
  flex-shrink: 1;
}

.contact-form-cell-email,
.contact-form-message,
.contact-form-submit {
  --cell-width: 100%;
}

/* ----------------------------------------------- */
@media (width <= 540px) {
  .contact-form,
  .contact-form-cell {
    --cell-width: 100%;
  }
}

/* ----------------------------------------------- */
label,
input,
select,
button[type="submit"],
textarea {
  --font-size: var(--font-size-body-fluid);

  appearance: none;
  font-size: var(--font-size);
  line-height: var(--type-scale-perfect-fourth);
}

textarea {
  --default-height: calc(var(--font-size) * 8);

  height: var(--default-height);
  resize: vertical;
}

/* ----------------------------------------------- */
label,
label em {
  color: var(--color-white);
  display: block;
  font-family: var(--font-family-libre-baskerville-bold);
}

label {
  position: relative;
}

label em {
  font-style: normal;
  margin-bottom: calc(var(--font-size) * 0.5);
}

/* ----------------------------------------------- */
input,
select,
textarea {
  --border-width: 1px;
  --surface-color: var(--color-black-900);
  --on-surface-color: var(--color-white);
  --padding: calc(var(--font-size) * 0.75);

  background-color: var(--surface-color);
  border-color: var(--on-surface-color);
  border-radius: 0;
  border-style: solid;
  border-width: var(--border-width);
  color: var(--on-surface-color);
  display: inline-flex;
  font-family: var(--font-family-libre-baskerville-regular);
  outline-color: transparent;
  outline-style: solid;
  outline-width: calc(var(--border-width) * 4);
  padding-bottom: var(--padding);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  transition-duration: var(--speed-fast);
  transition-property: outline-color;
  transition-timing-function: var(--easing-color-or-opacity);
  width: 100%;
}

input:hover,
select:hover,
textarea:hover {
  border-color: var(--color-black-600);
}

input:focus,
select:focus,
textarea:focus {
  border-color: var(--color-black-500);
  outline-color: var(--color-black-100);
  transition-duration: var(--speed-extra-fast);
}

button.contact-form-submit-button {
  --font-size: var(--font-size-body);
  --padding: calc(var(--font-size) * 0.75);
  --surface-color: var(--color-black-700);
  --on-surface-color: var(--color-white);

  align-items: center;
  background-color: var(--surface-color);
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  color: var(--on-surface-color);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-libre-baskerville-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);
  padding-bottom: var(--padding);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  place-content: center center;
  text-align: center;
  transition-duration: var(--speed-extra-fast), var(--speed-extra-fast);
  transition-property: background-color, color;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity);
  width: 120px;
}

button.contact-form-submit-button:hover {
  --surface-color: var(--color-black-600);

  border-color: var(--color-white);
}

button.contact-form-submit-button:active,
button.contact-form-submit-button:focus {
  --surface-color: var(--color-black-800);

  border-color: var(--color-black-500);
  transition-property: none;
}

/* ----------------------------------------------- */
form [role="alert"] {
  --font-size: var(--font-size-body-small);

  border-left: 4px solid var(--color-error);
  color: var(--color-white);
  display: block;
  font-family: var(--font-family-libre-baskerville-bold);
  font-size: var(--font-size);
  line-height: var(--type-scale-perfect-fourth);
  margin-bottom: 0;
  margin-top: calc(var(--gutter) / 4);
  padding-left: calc(var(--gutter) / 4);
}

/* ----------------------------------------------- */
.form-submit-with-success-message {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: calc(var(--gutter) / 2);
  place-content: center flex-start;
}

.form-submit-with-success-message-cell,
.form-submit-with-error-message-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
}

/* ----------------------------------------------- */
@media (width <= 480px) {
  .form-submit-with-success-message,
  .form-submit-with-error-message {
    flex-wrap: wrap;
  }

  .form-submit-with-success-message-cell,
  .form-submit-with-error-message-cell {
    flex-basis: 100%;
    flex-grow: 1;
  }
}
