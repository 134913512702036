/* stylelint-disable declaration-no-important */
@charset 'UTF-8';

:root {
  --animation-delay-short: 0.1s;
  --animation-delay-medium: 0.5s;
  --color-black: black;
  --color-white: white;
  --color-error: #d51f31;
  --color-success: #166534;

  /* Base: 900 */
  --color-black-900: #231f20;
  --color-black-800: #444041;
  --color-black-700: #56575a;
  --color-black-600: #777374;
  --color-black-500: #a19b9d;
  --color-black-400: #c0babc;
  --color-black-300: #e3dedf;
  --color-black-200: #f2eced;
  --color-black-100: #f9f3f5;
  --color-black-050: #fef8fa;
  --color-oyster-shell-900: #1d1b19;
  --color-oyster-shell-800: #3d3b39;
  --color-oyster-shell-700: #5c5a57;
  --color-oyster-shell-600: #706d6b;
  --color-oyster-shell-500: #989593;
  --color-oyster-shell-400: #b8b5b2;

  /* Base: 300 */
  --color-oyster-shell-300: #dcd9d6;
  --color-oyster-shell-200: #ece9e6;
  --color-oyster-shell-100: #f5f2ef;
  --color-oyster-shell-050: #fcf8f5;
  --font-family-libre-baskerville-bold: "libre-baskerville/bold", "Helvetica", "Arial", sans-serif;
  --font-family-libre-baskerville-italic: "libre-baskerville/italic", "Helvetica", "Arial",
    sans-serif;
  --font-family-libre-baskerville-regular: "libre-baskerville/regular", "Helvetica", "Arial",
    sans-serif;
  --font-size-h1: 96px;
  --font-size-h2: 60px;
  --font-size-h3: 48px;
  --font-size-h4: 34px;
  --font-size-h5: 24px;
  --font-size-h6: 20px;
  --font-size-body: 16px;
  --font-size-body-big: 23px;
  --font-size-body-small: 14px;
  --font-size-body-extra-small: 12px;
  --font-size-h1-fluid: clamp(3.355rem, 2.64rem + 3.586vw, 6.941rem);
  --font-size-h2-fluid: clamp(2.684rem, 2.178rem + 2.53vw, 5.214rem);
  --font-size-h3-fluid: clamp(2.145rem, 1.793rem + 1.76vw, 3.905rem);
  --font-size-h4-fluid: clamp(1.716rem, 1.474rem + 1.21vw, 2.936rem);
  --font-size-h5-fluid: clamp(1.375rem, 1.21rem + 0.825vw, 2.2rem);
  --font-size-h6-fluid: clamp(1.1rem, 0.99rem + 0.55vw, 1.65rem);
  --font-size-body-fluid: clamp(0.88rem, 0.814rem + 0.363vw, 1.243rem);
  --font-size-body-big-fluid: clamp(0.968rem, 0.8954rem + 0.3993vw, 1.3673rem);
  --font-size-body-small-fluid: clamp(0.704rem, 0.66rem + 0.22vw, 0.924rem);
  --font-size-button: 18px;
  --gutter: 2rem;
  --text-shadow-white: hsl(0deg 0% 100% / 75%) 0 1px 1px;
  --text-shadow-oyster-shell: hsl(30deg 8% 85% / 75%) 0 1px 1px;
  --text-shadow-black: hsl(0deg 0% 0% / 75%) 0 1px 1px;
}

@font-face {
  font-display: fallback;
  font-family: "libre-baskerville/bold";
  src: url("/fonts/libre-baskerville/bold.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "libre-baskerville/italic";
  src: url("/fonts/libre-baskerville/italic.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "libre-baskerville/regular";
  src: url("/fonts/libre-baskerville/regular.woff2") format("woff2");
}

[data-document-ready="false"] *,
[data-document-ready="false"] *::after,
[data-document-ready="false"] *::before {
  animation-play-state: paused !important;
}

@media (prefers-reduced-motion) {
  [data-document-ready="true"] *,
  [data-document-ready="true"] *::after,
  [data-document-ready="true"] *::before {
    animation-play-state: paused !important;
  }
}

/* ----------------------------------------------- */
body {
  background-color: var(--color-black-900);
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--color-white);
  font-family: var(--font-family-libre-baskerville-regular);
}

/* ----------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl,
legend {
  --vertical-margin: calc(var(--gutter) / 2);

  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
}

/* ----------------------------------------------- */
strong {
  font-family: var(--font-family-libre-baskerville-bold);
  font-style: normal;
}

/* ----------------------------------------------- */
h1,
h2,
h3 {
  font-family: var(--font-family-libre-baskerville-bold);
  font-size: var(--font-size-h4-fluid);
}

h1 {
  margin-top: 0;
}

h2 {
  font-size: var(--font-size-h6-fluid);
}

p {
  font-size: var(--font-size-body-fluid);
  line-height: var(--type-scale-golden-ratio);
}

/* ----------------------------------------------- */

.small-text {
  font-size: var(--font-size-body-small);
}

hr {
  --ruler-height: 2px;
  --vertical-margin: var(--gutter);

  background-color: var(--color-white);
  border: none;
  height: var(--ruler-height);
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
  padding: 0;
  width: 100%;
}

/* ----------------------------------------------- */
.button-unstyled,
.button-unstyled:hover,
.button-unstyled:active,
.button-unstyled:focus {
  appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  transition-property: none;
}

/* ----------------------------------------------- */
.contact-points a {
  color: var(--color-black);
}

/* ----------------------------------------------- */
body[data-route="home"] {
  background-image: url("/img/homepage-background-with-frame.png");
}

/* ----------------------------------------------- */
@media (width <= 540px) {
  body[data-route="home"] {
    background-image: url("/img/homepage-background-with-frame-small.jpg");
  }
}

/* ----------------------------------------------- */
.suspense-loader {
  --position-xy: 0;

  align-items: center;
  background-color: var(--color-black-900);
  color: var(--color-white);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: var(--position-xy);
  pointer-events: none;

  /* position: fixed; */
  top: var(--position-xy);
  visibility: hidden;
  width: 100vw;
  z-index: 1000;
}
