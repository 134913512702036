@charset 'UTF-8';

.site-menu-button,
.navigation-drawer {
  --site-menu-button-size: 64px;
}

.site-menu-button {
  --surface-color: transparent;
  --outline-color: transparent;

  align-items: center;
  background-color: var(--surface-color);
  border-color: var(--outline-color);
  border-radius: 100%;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: var(--site-menu-button-size);
  place-content: center center;
  position: relative;
  row-gap: calc(var(--gutter) / 4);
  width: var(--site-menu-button-size);
}

.site-menu-button em,
.site-menu-button em::before {
  --bar-color: var(--color-white);
  --bar-height: 2px;
  --bar-width: 60%;

  background-color: var(--bar-color);
  height: var(--bar-height);
  width: var(--bar-width);
}

.site-menu-button em {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  height: var(--bar-height);
  transform-origin: center;
  width: var(--bar-width);
}

.site-menu-button em:nth-child(2)::before {
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
}

[data-route="home"] .site-menu-button em,
[data-route="home"].site-menu-button em::before {
  --bar-color: var(--color-black-900);
}

/* ----------------------------------------------- */
.site-menu-button:hover {
  --surface-color: var(--color-black-800);
  --outline-color: var(--color-black-700);
}

.site-menu-button[data-menu-is-open="true"] {
  --surface-color: var(--color-black-800);
}

.site-menu-button[data-menu-is-open="true"] em,
.site-menu-button[data-menu-is-open="true"] em::before {
  background-color: var(--color-white);
}

[data-route="home"] .site-menu-button:hover {
  --surface-color: var(--color-black-400);
}

/* ----------------------------------------------- */
.site-menu-button[data-menu-is-open="true"] em:nth-child(1),
.site-menu-button[data-menu-is-open="true"] em:nth-child(3) {
  background-color: transparent;
}

.site-menu-button[data-menu-is-open="true"] em:nth-child(2) {
  rotate: 45deg;
}

.site-menu-button[data-menu-is-open="true"] em:nth-child(2)::before {
  opacity: 1;
  rotate: -90deg;
}

/* ----------------------------------------------- */
.gallery-navigation-button {
  --surface-color: transparent;
  --on-surface-color: var(--color-white);
  --outline-color: transparent;

  align-items: center;
  background-color: var(--surface-color);
  border-color: var(--outline-color);
  border-radius: 100%;
  border-style: solid;
  border-width: 1px;
  color: var(--on-surface-color);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: var(--gallery-navigation-button-button-size);
  place-content: center center;
  position: relative;
  row-gap: calc(var(--gutter) / 4);
  transition-duration: var(--speed-normal);
  transition-property: background-color;
  transition-timing-function: var(--easing-color-or-opacity);
  width: var(--gallery-navigation-button-button-size);
}

.gallery-navigation-button svg {
  fill: currentcolor;
  position: absolute;
  width: 75%;
}

.gallery-navigation-button[data-gallery-navigation-direction="back"] svg {
  transform: rotate(180deg);
}

.gallery-navigation-button:hover {
  --surface-color: var(--color-black-800);

  transition-duration: var(--speed-extra-fast);
}

.gallery-navigation-button:active,
.gallery-navigation-button:focus {
  --surface-color: var(--color-black-700);

  transition-property: none;
}

/* ----------------------------------------------- */
@media (width <= 768px) {
  .site-menu-button,
  .navigation-drawer {
    --site-menu-button-size: 48px;
  }
}
