@charset 'UTF-8';

.stacked-navigation {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: -4vh;
  padding: 0;
  place-content: center center;
  row-gap: calc(var(--gutter) * 1.5);
}

.stacked-navigation li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
}

.stacked-navigation a {
  align-items: flex-end;
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  outline: none;
  place-content: center center;
  position: relative;
}

.stacked-navigation a::after {
  --decoration-color: var(--color-white);
  --decoration-height: 2px;
  --decoration-width: 0;

  background-color: var(--decoration-color);
  bottom: calc(var(--decoration-height) * 2 * -1);
  content: "";
  display: block;
  height: var(--decoration-height);
  opacity: 0;
  position: absolute;
  transition-duration: var(--speed-fast), var(--speed-fast);
  transition-property: opacity, width;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-exiting);
  width: var(--decoration-width);
}

.stacked-navigation a:hover::after,
.stacked-navigation a:active::after,
.stacked-navigation a:focus::after,
.stacked-navigation a[aria-current="page"]::after,
.stacked-navigation a[data-is-in-current-directory="true"]::after {
  --decoration-width: 100%;

  opacity: 1;
  transition-duration: var(--speed-extra-fast), var(--speed-extra-fast);
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-exiting);
}

.stacked-navigation a:active::after,
.stacked-navigation a:focus::after,
.stacked-navigation a[aria-current="page"]::after,
.stacked-navigation a[data-is-in-current-directory="true"]::after {
  --decoration-color: var(--color-black-500);

  transition-property: none;
}

/* ----------------------------------------------- */
.wall-label {
  --width: 320px;
  --position-x: calc(50% - var(--width) / 2);
  --position-x-offset: calc(100% / 3);

  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: var(--font-size-body-fluid);
  left: var(--position-x);
  margin-left: auto;
  margin-right: auto;
  place-content: center flex-start;
  position: absolute;
  row-gap: calc(var(--gutter) * 0.75);
  transform: translateX(var(--position-x-offset));
  width: var(--width);
}

.wall-label dt {
  display: none;
}

/* ----------------------------------------------- */
@media (width <= 480px) {
  .wall-label {
    --width: 275px;
  }
}
