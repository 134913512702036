@charset 'UTF-8';

.top-app-bar {
  --horizontal-freespace: var(--gutter);
  --vertical-freespace: calc(var(--gutter) / 2);

  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: var(--horizontal-freespace);
  padding-right: var(--horizontal-freespace);
  padding-top: var(--vertical-freespace);
  place-content: center flex-start;
  position: absolute;
  width: 100%;
  z-index: 100;
}

/* ----------------------------------------------- */

/* [data-route="home"] .top-app-bar {
  display: none;
} */

[data-route="menu"] .site-menu-button {
  display: none;
}

/* ----------------------------------------------- */
@media (width <= 540px) {
  .top-app-bar {
    --horizontal-freespace: calc(var(--gutter) / 2);
  }

  .site-menu-button {
    margin-left: 6px;
  }
}
